import { StaticQuery, graphql } from "gatsby";
import React from "react";
import { PageWrapper } from "~components/Core";
import RequestQuote from "~sections/digital/RequestQuote"
import FooterSix from "~sections/digital/FooterSix"
import { Container } from "react-bootstrap";

const header = {
    headerClasses: "site-header site-header--menu-end dark-header site-header--sticky",
    containerFluid: false,
    buttonBlock: <RequestQuote />,
    darkLogo: false,
}

const CustomerSupport = () => (
    <StaticQuery
        query={customerSupportData}
        render={(data) => {
            return (
                <PageWrapper headerConfig={header}>
                    <div style={{ minHeight: "74px", background: "#19191B" }}></div>
                    <Container className="mt-7" style={{ minHeight: "50vh" }}>
                        <p
                            className="pale-yellow fz-17 fw-300 text-align-justify"
                            dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
                        ></p>
                    </Container>

                    <FooterSix />
                </PageWrapper>
            );
        }}
    />
);

export const customerSupportData = graphql`
  query CustomerSupportQuery {
    markdownRemark(
      fileAbsolutePath: {
        regex: "posts/customerSupport/"
      }
    ) {
      html
    }
  }
`;

export default CustomerSupport;